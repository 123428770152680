<template>
  <div>
    <div class="row whitelist-page">
      <div class="col-lg-12">
        <KTPortlet v-bind:title="$t('MENU.WHITELIST')">
          <template v-slot:toolbar>
            <b-button
              variant="primary"
              class="mr-4"
              @click="openCreateModal()"
              v-if="canAdd"
            >
              {{ $t("COMMON.NEW") }}
            </b-button>
            <b-button
              variant="danger"
              v-b-modal.confirm-1
              class="mr-4"
              v-if="canRemove"
              :disabled="whitelistTable.selected.length == 0"
            >
              {{ $t("COMMON.DELETE") }}
            </b-button>
            <b-button variant="success" v-b-modal.import-modal v-if="canAdd">
              {{ $t("FILTERING.WHITELIST.IMPORT") }}
            </b-button>
          </template>
          <template v-slot:body>
            <div class="row table-filter">
              <div class="col-sm-6">
                <b-form-group
                  :label="$t('COMMON.SEL_DOMAIN')"
                  label-for="select-domain"
                >
                  <model-list-select :list="selectDomain.allDomains"
                    v-model="selectDomain.selectedDomain"
                    option-value="domain"
                    option-text="domain"
                    :placeholder="$t('COMMON.SEL_DOMAIN')"
                    @input="onChangeDomain"
                    id="select-domain">
                  </model-list-select>
                </b-form-group>
              </div>
              <div class="col-sm-6">
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('COMMON.SEARCH')"
                  class="cus-searchBox"
                  single-line
                  hide-details
                ></v-text-field>
              </div>
              <div class="col-sm-6">
                <v-checkbox
                  v-model="include_users"
                  class="include-check"
                  color="rgba(0, 0, 0, 0.54)"
                  :label="$t('FILTERING.WHITELIST.INCLUDE_USERS_WHITELIST')"
                  @change="getWB()"
                ></v-checkbox>
              </div>
            </div>
            <v-data-table
              v-model="whitelistTable.selected"
              :headers="headers"
              :items="whitelistTable.whitelist"
              :single-select="whitelistTable.singleSelect"
              :search="search"
              show-select
              item-key="id"
              class="elevation-1"
              ref="whitelistTable"
              :footer-props="{
                showFirstLastPage: true,
              }"
            >
              <template v-slot:item.action="{ item }">
                <v-icon
                  medium
                  color="dark"
                  @click="saveDeltem(item)"
                  v-b-modal.confirm-2
                  v-b-tooltip.hover
                  v-if="canRemove"
                  :title="$t('COMMON.DELETE')"
                >
                  delete
                </v-icon>
                <v-icon
                  medium
                  color="dark"
                  class="mr-2"
                  @click="showItemInfor(item)"
                  v-b-tooltip.hover
                  v-if="canAdd"
                  :title="$t('COMMON.EDIT')"
                >
                  edit
                </v-icon>
              </template>
            </v-data-table>
            <!-- ----------add whitelist modal------------- -->
            <b-modal
              id="addWhitelist"
              ref="addWhitelist"
              :title="
                isEdit == false
                  ? $t('FILTERING.WHITELIST.ADD_WHITELIST')
                  : editTitle
              "
            >
              <b-container fluid>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.DOMAIN") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.domain.selected"
                      value-field="domain_value"
                      text-field="domain"
                      :options="addForm.domain.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label for="address">{{ $t("COMMON.ADDRESS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="address"
                      v-model="$v.addForm.address.$model"
                      :state="validateState('address')"
                      :placeholder="$t('COMMON.ADDRESS')"
                      aria-describedby="input-address-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-address-feedback">
                      {{ $t("VALIDATION.EMAIL_FIELD") }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.CHECK_VIRUS") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.check_virus.selected"
                      value-field="value"
                      text-field="name"
                      :options="check_virusData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label>{{ $t("FILTERING.WHITELIST.IGNORE_SPF") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.ignorespf.selected"
                      value-field="value"
                      text-field="name"
                      :options="ignorespfData"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-4" v-if="addForm.ignorespf.selected == 1">
                  <b-col sm="5">
                    <label for="spfip">{{ $t("FILTERING.WHITELIST.IGNORE_SPF_IP") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-input
                      id="spfip"
                      v-model="addForm.spfip"
                      :placeholder="$t('FILTERING.WHITELIST.SENDER_IP')"
                    ></b-form-input>
                  </b-col>
                </b-row>
                <b-row class="my-4">
                  <b-col sm="5">
                    <label>{{
                      $t("FILTERING.WHITELIST.APPLY_TO_HEADERS")
                    }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.apply_to_header.selected"
                      value-field="value"
                      text-field="name"
                      :options="apply_headersData"
                    ></b-form-select>
                  </b-col>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('addWhitelist')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="createItem()"
                  v-if="!isEdit"
                >
                  {{ $t("FILTERING.WHITELIST.ADD_WHITELIST") }}
                </b-button>
                <b-button
                  variant="primary"
                  v-on:click="updateItem()"
                  v-if="isEdit"
                >
                  {{ $t("FILTERING.WHITELIST.UPDATE_WHITELIST") }}
                </b-button>
              </template>
            </b-modal>

            <!-- ----------import whitelist modal------------- -->
            <b-modal
              id="import-modal"
              ref="importWhitelist"
              :title="$t('FILTERING.WHITELIST.IMPORT')"
            >
              <b-container fluid>
                <b-alert show variant="secondary">
                  {{ $t("FILTERING.WHITELIST.IMPORT_DEC") }}
                </b-alert>
                <b-row class="my-1">
                  <b-col sm="5">
                    <label>{{ $t("COMMON.DOMAIN") }}</label>
                  </b-col>
                  <b-col sm="7">
                    <b-form-select
                      v-model="addForm.domain.selected"
                      value-field="domain"
                      text-field="domain"
                      :options="addForm.domain.data"
                    ></b-form-select>
                  </b-col>
                </b-row>
                <b-row class="my-3">
                  <b-form-file
                    v-model="addForm.whitelistCsv"
                    :placeholder="$t('FILTERING.WHITELIST.CHOOSE_PLACEHOLDER')"
                    :drop-placeholder="
                      $t('FILTERING.WHITELIST.DROP_PLACEHOLDER')
                    "
                    multiple
                    @change="onFileChange"
                  ></b-form-file>
                </b-row>
              </b-container>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="modalClose('importWhitelist')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="primary" v-on:click="importCsv()">
                  {{ $t("COMMON.ADD") }}
                </b-button>
              </template>
            </b-modal>

            <!-- ----------- delete one whitelist confirm modal -->
            <b-modal
              id="confirm-2"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteOneItem">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------- delete whitelists confirm modal -->
            <b-modal
              id="confirm-1"
              :title="$t('COMMON.DEL_CONFIRM')"
              ref="delConfirm-modal"
            >
              <p class="my-1">
                {{ $t("COMMON.DEL_WARNING") }}
              </p>
              <template v-slot:modal-footer>
                <b-button
                  variant="outline-secondary"
                  v-on:click="confirmModalClose('delConfirm-modal')"
                >
                  {{ $t("COMMON.CANCEL") }}
                </b-button>
                <b-button variant="danger" v-on:click="deleteItems">
                  {{ $t("COMMON.DELETE") }}
                </b-button>
              </template>
            </b-modal>
            <!-- ----------delete confirm modal end------------- -->
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import { validationMixin } from "vuelidate";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { postRequest } from "@/api/request.js";
import { required } from "vuelidate/lib/validators";
/* ---custom validation rule---- */
const mustBeDot = (value) =>
  value.lastIndexOf(".") > 0 && value.length - value.lastIndexOf(".") != 1;

export default {
  mixins: [validationMixin],
  name: "aliases",
  components: {
    KTPortlet,
  },
  data: () => ({
    search: "",
    whitelistTable: {
      singleSelect: false,
      whitelist: [],
      selected: [],
    },
    selectDomain: {
      selectedDomain: {},
      allDomains: [],
    },
    addForm: {
      domain: {
        selected: "",
        data: [],
      },
      address: "",
      check_spam: {
        selected: 0,
      },
      check_virus: {
        selected: 1,
      },
      ignorespf: {
        selected: 0,
      },
      spfip: "",
      apply_to_header: {
        selected: 0,
      },
      whitelistCsv: null,
    },
    delItem: "",
    importData: [],
    isEdit: false,
    updateLid: "",
    editTitle: "",
    include_users: false,
  }),
  computed: {
    headers: function () {
      return this.include_users
        ? [
            { text: this.$t("COMMON.ADDRESS"), value: "address" },
            { text: this.$t("COMMON.USERNAME"), value: "username" },
            // { text: this.$t("COMMON.CHECK_SPAM"), value: "check_spam" },
            { text: this.$t("COMMON.CHECK_VIRUS"), value: "check_virus" },
            {
              text: this.$t("FILTERING.WHITELIST.IGNORE_SPF"),
              value: "ignorespf",
            },
            { text: this.$t("FILTERING.WHITELIST.IGNORE_SPF_IP"), value: "spf_ip" },
            {
              text: this.$t("FILTERING.WHITELIST.APPLY_TO_HEADERS"),
              value: "apply_to_header",
            },
            {
              text: this.$t("COMMON.ACTION"),
              value: "action",
              sortable: false,
            },
          ]
        : [
            { text: this.$t("COMMON.ADDRESS"), value: "address" },
            { text: this.$t("COMMON.CHECK_VIRUS"), value: "check_virus" },
            {
              text: this.$t("FILTERING.WHITELIST.IGNORE_SPF"),
              value: "ignorespf",
            },
            { text: this.$t("FILTERING.WHITELIST.IGNORE_SPF_IP"), value: "spf_ip" },
            {
              text: this.$t("FILTERING.WHITELIST.APPLY_TO_HEADERS"),
              value: "apply_to_header",
            },
            {
              text: this.$t("COMMON.ACTION"),
              value: "action",
              sortable: false,
            },
          ];
    },
    // check_spamData: function() {
    //   return [
    //     { name: this.$t("COMMON.YES"), value: 1 },
    //     { name: this.$t("COMMON.NO"), value: 0 }
    //   ];
    // },
    check_virusData: function () {
      return [
        { name: this.$t("COMMON.YES"), value: 1 },
        { name: this.$t("COMMON.NO"), value: 0 },
      ];
    },
    ignorespfData: function () {
      return [
        { name: this.$t("COMMON.YES"), value: 1 },
        { name: this.$t("COMMON.NO"), value: 0 },
      ];
    },
    apply_headersData: function () {
      return [
        { name: this.$t("COMMON.YES"), value: 1 },
        { name: this.$t("COMMON.NO"), value: 0 },
      ];
    },
    canAdd: function () {
      return this.hasPermission("filtering", 5);
    },
    canRemove: function () {
      return this.hasPermission("filtering", 7);
    },
  },
  created() {
    postRequest({
      action: "getAllDomains",
      token: localStorage.id_token,
    }).then((res) => {
      if (res) {
        if (!res.returncode) {
          res.returndata = [{ domain: res.returndata }];
        }
        this.selectDomain.allDomains = res.returndata;
        this.selectDomain.selectedDomain = res.returndata[0];
        this.addForm.domain.data = res.returndata;
        let that = this;
        this.addForm.domain.data.forEach(function (item, index) {
          that.addForm.domain.data[index].domain_value = item.domain;
        });
        this.addForm.domain.selected = this.addForm.domain.data[0].domain_value;
        this.getWhitelistReqest(this.selectDomain.selectedDomain.domain);
      }
    });
  },
  validations: {
    addForm: {
      address: {
        required,
        mustBeDot,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.FILTERING") + " -> " + this.$t("MENU.WHITELIST") },
    ]);
  },
  methods: {
    /* -----validation---- */
    validateState(name) {
      const { $dirty, $error } = this.$v.addForm[name];
      return $dirty ? !$error : null;
    },
    addformReset() {
      this.addForm.address = "";
      // this.addForm.check_spam.selected = this.check_spamData[0].value;
      this.addForm.check_virus.selected = this.check_virusData[0].value;
      this.addForm.ignorespf.selected = this.ignorespfData[1].value;
      this.addForm.spfip = "";
      this.addForm.apply_to_header.selected = this.apply_headersData[1].value;
    },
    /*  -----modal close------- */
    modalClose(modalRef) {
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.addformReset();
      this.addForm.whitelistCsv = null;
      this.$refs[modalRef].hide();
    },
    confirmModalClose(modalItem) {
      this.$refs[modalItem].hide();
    },

    /* ---------read item---------- */
    getWhitelistReqest(domain) {
      this.whitelistTable.whitelist = [];
      this.whitelistTable.selected = [];
      postRequest({
        action: "getWBLists",
        token: localStorage.id_token,
        type: 1,
        domain: domain,
        include_userslist: this.include_users ? 1 : 0,
      }).then((res) => {
        if (res.returncode) {
          for (let i = 0; i < res.returndata.length; i++) {
            // if (res.returndata[i].check_spam == 0) {
            //   res.returndata[i].check_spam = this.$t("COMMON.NO");
            // } else {
            //   res.returndata[i].check_spam = this.$t("COMMON.YES");
            // }
            if (res.returndata[i].check_virus == 0) {
              res.returndata[i].check_virus = this.$t("COMMON.NO");
            } else {
              res.returndata[i].check_virus = this.$t("COMMON.YES");
            }
            if (res.returndata[i].ignorespf == 0) {
              res.returndata[i].ignorespf = this.$t("COMMON.NO");
            } else {
              res.returndata[i].ignorespf = this.$t("COMMON.YES");
            }
            if (res.returndata[i].apply_to_header == 0) {
              res.returndata[i].apply_to_header = this.$t("COMMON.NO");
            } else {
              res.returndata[i].apply_to_header = this.$t("COMMON.YES");
            }
          }
          this.whitelistTable.whitelist = res.returndata;
        } else {
          this.whitelistTable.whitelist = [];
        }
      });
    },
    getWhitelistForSelectedDomain(selectedDomain) {
      this.getWhitelistReqest(selectedDomain);
    },

    /* ------create item---- */
    openCreateModal() {
      this.isEdit = false;
      this.addForm.domain.selected = this.selectDomain.selectedDomain.domain;
      this.$refs["addWhitelist"].show();
    },
    createItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.address.$anyError) {
        return;
      }
      const selectedDomainForAdd = this.addForm.domain.selected;
      const selectedDomainForPage = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "addWhitelist",
        token: localStorage.id_token,
        address: this.addForm.address,
        check_spam: this.addForm.check_spam.selected,
        check_virus: this.addForm.check_virus.selected,
        ignorespf: this.addForm.ignorespf.selected,
        spfip: this.addForm.ignorespf.selected == 1 ? this.addForm.spfip : "",
        apply_to_header: this.addForm.apply_to_header.selected,
        domain: selectedDomainForAdd,
      }).then((res) => {
        if (res.returncode) {
          if (selectedDomainForAdd == selectedDomainForPage)
            this.getWhitelistReqest(selectedDomainForAdd);
          this.makeToastVariant("success", res.returndata);
          this.addformReset();
          this.modalClose("addWhitelist");
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    /* ------create item end---- */
    /* ------- import item ------ */
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createInput(files[0]);
    },
    createInput(file) {
      // eslint-disable-next-line no-unused-vars
      let promise = new Promise((resolve, reject) => {
        var reader = new FileReader();
        var vm = this;
        // eslint-disable-next-line no-unused-vars
        reader.onload = (e) => {
          resolve((vm.fileinput = reader.result));
        };
        reader.readAsText(file);
      });

      promise.then(
        (result) => {
          this.importData = this.fileinput.split("\n");
          return result;
        },
        (error) => {
          return error;
        }
      );
    },
    importCsv() {
      const selectedDomainForPage = this.selectDomain.selectedDomain.domain;
      const addressData = this.importData;
      let isSuccess = false;
      for (let i = 0; i < addressData.length; i++) {
        if (addressData[i] != "") {
          postRequest({
            action: "addWhitelist",
            token: localStorage.id_token,
            address: addressData[i].trim(),
            check_spam: 0,
            check_virus: 1,
            ignorespf: 0,
            apply_to_header: 0,
            domain: selectedDomainForPage,
          }).then((res) => {
            if (res && res.returncode) {
              this.makeToastVariant("success", res.returndata);
              isSuccess = true;
            } else {
              this.makeToastVariant("danger", res.returndata);
            }
          });
        }
      }
      this.getWhitelistReqest(selectedDomainForPage);
      if (isSuccess) this.modalClose("importWhitelist");
    },
    /* -------delete item----- */
    saveDeltem(item) {
      this.delItem = item;
    },

    deleteRequest(lid) {
      postRequest({
        action: "removeWBList",
        token: localStorage.id_token,
        lid: lid,
      }).then((res) => {
        this.confirmModalClose("delConfirm-modal");
        if (res.returncode) {
          this.makeToastVariant("success", res.returndata);
          for (let i = 0; i < this.whitelistTable.whitelist.length; i++) {
            if (this.whitelistTable.whitelist[i].id == lid) {
              this.whitelistTable.whitelist.splice(i, 1);
            }
          }
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },
    deleteOneItem() {
      const item = this.delItem;
      this.deleteRequest(item.id);
    },
    deleteItems() {
      if (this.whitelistTable.selected.length > 0) {
        this.whitelistTable.selected.forEach((el) => {
          this.deleteRequest(el.id);
        });
      }
    },

    /* -------edit item----- */
    showItemInfor(item) {
      const lid = item.id;
      this.editTitle = this.$t("COMMON.UPDATE") + item.address;
      this.isEdit = true;
      this.updateLid = item.id;
      postRequest({
        action: "getWBList",
        token: localStorage.id_token,
        lid: lid,
      }).then((res) => {
        if (res.returncode) {
          this.$refs["addWhitelist"].show();
          this.addForm.domain.selected = res.returndata.domain;
          this.addForm.address = res.returndata.address;
          // this.addForm.check_spam.selected = res.returndata.check_spam;
          this.addForm.check_virus.selected = res.returndata.check_virus;
          this.addForm.ignorespf.selected = res.returndata.ignorespf;
          this.addForm.spfip = res.returndata.spf_ip;
          this.addForm.apply_to_header.selected =
            res.returndata.apply_to_header;
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
      });
    },

    updateItem() {
      this.$v.addForm.$touch();
      if (this.$v.addForm.address.$anyError) {
        return;
      }
      const selectedDomainForAdd = this.addForm.domain.selected;
      const selectedDomainForPage = this.selectDomain.selectedDomain.domain;
      postRequest({
        action: "updateWhitelist",
        token: localStorage.id_token,
        address: this.addForm.address,
        check_spam: this.addForm.check_spam.selected,
        check_virus: this.addForm.check_virus.selected,
        ignorespf: this.addForm.ignorespf.selected,
        spfip: this.addForm.ignorespf.selected == 1 ? this.addForm.spfip : "",
        apply_to_header: this.addForm.apply_to_header.selected,
        domain: selectedDomainForAdd,
        lid: this.updateLid,
      }).then((res) => {
        this.modalClose("addWhitelist");
        if (res.returncode) {
          if (selectedDomainForAdd == selectedDomainForPage)
            this.getWhitelistReqest(selectedDomainForAdd);
          this.makeToastVariant("success", res.returndata);
        } else {
          this.makeToastVariant("danger", res.returndata);
        }
        this.addformReset();
      });
    },

    getWB() {
      this.getWhitelistReqest(this.selectDomain.selectedDomain.domain);
    },

    /* ----notify---- */
    makeToastVariant(variant = null, msg) {
      let title = this.$t("COMMON.ERROR");
      if (variant == "success") {
        title = this.$t("COMMON.SUCCESS");
      }
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        // autoHideDelay: 5000,
        solid: true,
      });
    },
    /* ---- change domain dropdown ---- */
    onChangeDomain(newValue) {
      this.getWhitelistForSelectedDomain(newValue.domain);
    },
  },
};
</script>
